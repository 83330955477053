// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Breadcrumbs = React.memo(({ crumbs, mediaState }) => (
  <p className="breadcrumbs">
    {map(crumbs, ({ title, slug }, index) => (
      <span>
        {index !== 0 && <Fragment>&nbsp;</Fragment>}
        <Link to={slug}>{title}</Link>&nbsp;»
      </span>
    ))}
  </p>
))

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedBreadcrumbs = compose(
  withState // Add state
)(Breadcrumbs)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedBreadcrumbs
